
const PartNames = {
  NAVIGATE: 'navigate',
  REPORTING: 'reporting',
  REGISTRATION: 'registration',
  ANSWER: 'answer',
  PRACTICE: 'practice',
  REPORT: 'report',
  COMPLAINT: 'complaint',
  WARRANTY: 'warranty',
  QRCODE: 'qrcode',
  CARD: 'card'
}

const CatNavigate = {
  title: '导航台',
  name: PartNames.NAVIGATE,
  navigated: true
}

const CatReporting = {
  title: '我的申报',
  name: PartNames.REPORTING,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatRegistration = {
  title: '我的报名',
  name: PartNames.REGISTRATION,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatAnwser = {
  title: '我的答题',
  name: PartNames.ANSWER,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatPractice = {
  title: '我的练习',
  name: PartNames.PRACTICE,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatReport = {
  title: '我的举报',
  name: PartNames.REPORT,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatComplaint = {
  title: '我的举报',
  name: PartNames.COMPLAINT,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatWarranty = {
  title: '我的报修',
  name: PartNames.WARRANTY,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatQrcode = {
  title: '二维码名片',
  name: PartNames.QRCODE,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatCard = {
  title: '名片夹',
  name: PartNames.CARD,
  href: '',
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const myPreset = {
  Names: PartNames,
  Parts: {
    [PartNames.NAVIGATE]: CatNavigate,
    [PartNames.REPORTING]: CatReporting,
    [PartNames.REGISTRATION]: CatRegistration,
    [PartNames.ANSWER]: CatAnwser,
    [PartNames.PRACTICE]: CatPractice,
    [PartNames.REPORT]: CatReport,
    [PartNames.COMPLAINT]: CatComplaint,
    [PartNames.WARRANTY]: CatWarranty,
    [PartNames.QRCODE]: CatQrcode,
    [PartNames.CARD]: CatCard
  }
}

export default myPreset
