<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="my_center"
      space="48"
      color="grey lighten-4"
    >
      <v-container>
        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            cols="12"
            md="3"
            class="hidden-sm-and-down"
          >
            <land-categories
              :title="navCategory.title"
              :items="navCategory.items"
              @click="onCategory"
            />
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <div
              class="d-flex flex-row justify-space-between align-center pt-1 pb-5"
            >
              <v-breadcrumbs
                :items="navCrumbs"
                large
                class="pa-0"
              />
              <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="drawer = !drawer"
              />
            </div>
            <v-row
              class="pa-5 white"
            />
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>

  import mixMyBanner from '@/pages/mixins/my/mix.my.banner'
  import mixMyCategory from '@/pages/mixins/my/mix.my.category'
  import mixSpeedDial from '@/pages/mixins/mix.speeddial'

  export default {
    metaInfo () {
      return {
        title: this.pageTitle
      }
    },
    mixins: [
      mixMyBanner,
      mixMyCategory,
      mixSpeedDial
    ],
    data () {
      return {
        drawer: false,
        pageTitle: '个人中心'
      }
    },
    created () {
      this.speedDial.items.push(
        this.dialHome,
        this.dialComplaints,
        this.dialMaintain,
        this.dialReport
      )

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()
    }
  }
</script>
