
import myPreset from '@/pages/mixins/my/mix.my.preset'

const MyParts = myPreset.Parts
const MyNames = myPreset.Names

const NavHome = {
  text: '首页',
  disabled: false,
  href: '/',
}

const NavSelf = {
  text: '个人中心',
  disabled: false,
  href: '/my',
}

const NavCurrent = {
  text: '导航台',
  disabled: true,
  href: '',
}

export default {
  data () {
    return {
      navigated: true,
      navHome: NavHome,
      navSelf: NavSelf,
      navCrumbs: [
        NavHome,
        NavSelf,
        NavCurrent
      ],
      navCategory: {
        title: '个人中心',
        name: 'my',
        active: 0,
        items: [
          MyParts[MyNames.NAVIGATE],
          MyParts[MyNames.REPORTING],
          MyParts[MyNames.REGISTRATION],
          MyParts[MyNames.ANSWER],
          MyParts[MyNames.PRACTICE],
          MyParts[MyNames.COMPLAINT],
          MyParts[MyNames.WARRANTY],
          MyParts[MyNames.QRCODE],
          MyParts[MyNames.CARD]
        ]
      }
    }
  },
  methods: {
    getCategory (name) {
      let result = null
      const cats = this.navCategory.items
      for (const i in cats) {
        const cat = cats[i]
        if (cat.name === name) {
          result = cat
          break
        }
      }
      return result
    },
    activeCategory (name) {
      let result = 0
      const cnt = this.navCategory.items.length
      for (let i = 0; i < cnt; i++) {
        const item = this.navCategory.items[i]
        if (item.name === name) {
          result = i
          break
        }
      }

      this.navCategory.active = result
    },
    focusCategory (item) {
    },
    onCategory (ev) {
      const item = ev.item
      this.focusCategory(item)
      this.activeCategory(item.name)
    }
  }
}
