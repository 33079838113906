
import mixBannerBase from '@/pages/mixins/mix.banner.base'

export default {
  mixins: [mixBannerBase],
  data () {
    return {
    }
  },
  created () {
    this.banTopicId = '30955290309427200'
    this.navSelf.text = '个人中心'
    this.navSelf.href = ''
  },
  methods: {
  }
}
